import $ from 'jquery';
import 'select2';                       // globally assign select2 fn to $ element

$( document ).on('turbolinks:load', function() {
	if($('header, #index').length > 0){
		$('#mobile-button').on('click', function(){
			console.log('button click');
			if($('#mobile-menu').is(":visible"))
				$('#mobile-menu').slideUp();
			else
				$('#mobile-menu').slideDown();
		});
		$(window).on('resize', function() {
			$('#mobile-menu').slideUp();
		});
		// close menu on off click
		$(document).on('click', function(event){
			var target = $( event.target );
			if(!target.is('#mobile-menu ul, #mobile-menu li, #mobile-menu a, #mobile-button, #mobile-button p, .bar'))
				$('#mobile-menu').slideUp();
		});
	}

  $('.remove-vector-file').on('click', function(){
    $(this).parents('span').find('input').remove();
    $(this).html('Flagged for removal.');
    return false;
  });

  $(".taggable").select2(
    {
      tags: true,
      tokenSeparators: [',']
    }
  );
});

$( document ).on('ready turbolinks:load', function() {
  $('#designer_panel_category_ids').select2();
  $('#designer_shape_category_ids').select2();
  $('#designer_component_category_ids').select2();

  $('#user_select').select2();

  $('.nav-tabs a').on('click', function(){
    $('.nav-tabs li').removeClass('active');
    $(this).parent().addClass('active');
    $('.tab-content .tab-pane').hide();
    $($(this).attr('href')).show();
    return false;
  });

  if($('.nav-tabs a').length > 0)
     $('.nav-tabs a').first().trigger('click');

  $('.collapsable h3').on('click', function(){
    $('.contents', $(this).parent()).toggle();

    if($('.contents', $(this).parent()).css('display') == 'none'){
      $('i', $(this)).removeClass('fa-angle-down');
      $('i', $(this)).addClass('fa-angle-right');
    } else {
      $('i', $(this)).removeClass('fa-angle-right');
      $('i', $(this)).addClass('fa-angle-down');
    }
  });

});

window.initTaggable = function() {
  $(".taggable").select2(
    {
      tags: true,
      tokenSeparators: [',']
    }
  );
}