import Cleave from 'cleave.js';

$(document).on('turbolinks:load', function() {

  $('#registration_password_confirmation').on('keyup', function () {
    if ($('#registration_password').val() == $('#registration_password_confirmation').val()) {
      $('#matches').html('✔').css('color', 'green');
    } else
      $('#matches').html('✖').css('color', 'red');
  });


  if($('#registration_credit_card_number').length > 0) {
    new Cleave('#registration_credit_card_number', {
      creditCard: true
    });
  }

  if($('#activation_credit_card_number').length > 0) {
    new Cleave('#activation_credit_card_number', {
      creditCard: true
    });
  }

  if($('#credit_card_number').length > 0){
    new Cleave('#credit_card_number', {
      creditCard: true
    });
  }

  $('.tile').on('click', function(){
    if($(this).hasClass('trial')) {
      $('#registration_trial').prop('checked', 'checked');
      $('#payment-info').hide();
    } else {
      $('#registration_trial').prop('checked', '');
      $('#payment-info').show();
    }

    $('.tile').removeClass('active');
    $(this).addClass('active');
  });
});