$( document ).on('turbolinks:load', function() {
  
      // handle question answer dropdowns
      $('.arrow, #questions .title').click(function(){
  
        // show text in box
        var box = $(this).parent();
  
        if($(box).find('.text').is(':hidden')){
          // show text box
          $(box).find('.text').slideDown(300);
  
          // apply styles to arrow and title
          $(box).find('.arrow').addClass('active');
          $(box).find('.title').addClass('color-green');
        }else {
          // hide text box
          $(box).find('.text').slideUp(300);
          // remove styles
          $(box).find('.arrow').removeClass('active');
          $(box).find('.title').removeClass('color-green');
        }
  
      });
  
  });


// // handle question answer dropdowns
// $('.arrow, #questions .title').click(function(){

//     // show text in box
//     var box = $(this).parent();

//     if($(box).find('.text').is(':hidden')){
//       // show text box
//       $(box).find('.text').slideDown(300);

//       // apply styles to arrow and title
//       $(box).find('.arrow').addClass('active');
//       $(box).find('.title').addClass('color-green');
//     }else {
//       // hide text box
//       $(box).find('.text').slideUp(300);
//       // remove styles
//       $(box).find('.arrow').removeClass('active');
//       $(box).find('.title').removeClass('color-green');
//     }

//   });
