import Sortable from 'sortablejs';
import Rails from '@rails/ujs';

$( document ).on('turbolinks:load', function() {
  if($('#selected-materials').length == 0)
    return;

  new Sortable($('#selected-materials')[0], {
    animation: 150,
    ghostClass: 'blue-background-class',
    onUpdate: function (evt) {
      [].forEach.call(evt.from.getElementsByClassName('material'), function (el,index) {
        el.setAttribute("data-sort",index);
      });
    },
  });

  var sorted = {};
  $('#update-material-order').on('click', function(){
    $('#selected-materials .material').each(function(){
      sorted[$(this).data('id')] = $(this).data('sort');
    });
  });

  $('#update-material-order').on('click', function(){
    if(Object.keys(sorted).length == 0)
      return;

    Rails.ajax({
      url: '/account/material_selections/sort',
      type: 'POST',
      dataType: 'json',
      accept: 'json',
      data: {
        order: sorted
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(options.data);
        return true;
      },
      success: function(data) {
        alert('Material selection ordering updated.')
      },
      error: function(data) {
        console.log(data);
      }
    });
  });
});