$(document).on('turbolinks:load', function() {
  $('.margin, .cost').on('keyup', function(){
    var margin = parseFloat($(this).parents('.row').find('.margin').val());
    var cost   = parseFloat($(this).parents('.row').find('.cost').val());

    if(isNaN(margin) || isNaN(cost))
      return;

    var revenue = cost / (1 - (margin / 100));
    var multiple = revenue / cost;

    $(this).parents('.row').find('.multiple').html(Math.round(multiple * 100) / 100);
  });

  if($('input.margin').length > 0) {
    $('.margin').each(function(){
      $(this).trigger('keyup');
    });
  }

  $('#copy_from_spot').on('change', function(){
    var sure = confirm('Are you sure?');
    if(sure) {
      var copying;
      if($(this).val() == 'Spot 1') {
        copying = 0;
      }else if($(this).val() == 'Spot 2') {
        copying = 1;
      }else if($(this).val() == 'Spot 3') {
        copying = 2;
      }

      var visible;
      if($('#ps-tab-0').css('height') !== '1px') {
        visible = 0;
      }else if($('#ps-tab-1').css('height') !== '1px') {
        visible = 1;
      }else {
        visible = 2;
      }

      if(copying == visible)
        return;

      $('#ps-tab-' + copying + ' input').each(function(){
        var id = $(this).attr('id');
        var newID = id.replace(copying, visible);
        $('#' + newID).val($(this).val());
      });
    }
  });

  $('#ps-select-all').on('click', function(){
    $('#pricesheet_user_ids option').each(function(){
      $(this).prop('selected', true);
    });
  });

  $('#apply-global').on('click', function(){
    val = $('#global_override').val();
    $('#multipliers-form input[type="text"]').val(val);
    $('.edit_pricesheet .margin').val(val);
    $('.margin').each(function(){
      $(this).trigger('keyup');
    });
  });
});