// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require('select2')
require("sortablejs");
require("packs/faq")
require("packs/material_selections")
require("packs/components")
require("packs/signup")
require("packs/pricesheets")